import React from "react";

export interface IRoute {
  path: string;
  component?: React.ComponentType;
  element?: JSX.Element;
}

export enum RouteNames {
  LOGIN = "/login",
  SCAN = "/scan",
  REGISTER = "/register",
  REGISTER_USER = "/",
  REGISTER_USER_SUCCESS = "/register-user/success",
  REGISTER_READY_USER = "/register-user/ready",
  REGISTER_READY_USER_SUCCESS = "/register-user/ready/success",
  UNREGISTER = "/unregister",
  ADMIN = "/admin",
  VOLUNTEERS = "/admin/volunteers",
  VOLUNTEERS_STATISTICS = "/admin/volunteers/statistics",
  STATISTICS = "/admin/statistics",
}
