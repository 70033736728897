import React from "react";

interface IProps {
  columns: string[];
  rows: Array<Array<string | JSX.Element>>;
}

const Table: React.FC<IProps> = ({ columns, rows }) => {
  return (
    <div className="table-container">
      <table className="table" width="100%">
        <tr className="table-header">
          {columns.map((column) => (
            <td className="table-cell">{column}</td>
          ))}
        </tr>
        {rows.map((row) => (
          <tr key={row.join()} className="table-row">
            {row.map((cell) => (
              <td className="table-cell">{cell}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
};

export default Table;
