import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import Button from "../components/UI/Button";
import { useNavigate } from "react-router-dom";

const SuccessRegisterPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/register-user/ready");
  };
  return (
    <div className="success-register-page">
      <div className="success-register-page-container">
        <Logo width="350px" />
        <div className="content">
          <p>Twoje konto zostało utworzone</p>
          <Button onClick={handleClick}>Zgłoś swoją dostępność</Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessRegisterPage;
