import React, { useState } from "react";
import Button from "./UI/Button";

type ButtonType = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const TimeButton: React.FC<ButtonType> = ({ onClick, ...props }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsActive(!isActive);

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Button
      className={`time-button ${isActive ? "active" : ""}`}
      onClick={handleClick}
      {...props}
    />
  );
};

export default TimeButton;
