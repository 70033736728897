import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import RegisterNav from "../components/RegisterNav";
import Input from "../components/UI/Input";
import Checkbox from "../components/UI/Checkbox";
import TextField from "../components/UI/TextField";
import Button from "../components/UI/Button";
import { useHttp } from "../hooks/useHttp";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../types/RouteTypes";

const RegisterPage = () => {
  const [data, setData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    languages: {
      PL: false,
      EN: false,
      UA: false,
      RU: false,
    },
    info: "",
  });
  const [usePersonalData, setUsePersonalData] = useState(false);
  const [error, setError] = useState("");
  const [showPersonalDataText, setShowPersonalDataText] = useState(false);

  const { request, loading } = useHttp();
  const navigate = useNavigate();

  const handleChangeUsagePersonalData = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUsePersonalData(event.target.checked);
  };

  const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === "text") {
      setData((prevData) => ({
        ...prevData,
        [event.target.name]: event.target.value,
      }));
    }

    if (event.target.type === "checkbox") {
      // @ts-ignore
      setData((prevData) => ({
        ...prevData,
        languages: {
          ...prevData.languages,
          [event.target.name]: event.target.checked,
        },
      }));
    }
  };

  const handleChangeInfo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData((prevData) => ({
      ...prevData,
      info: event.target.value,
    }));
  };

  const handleSendData = async () => {
    if (!data.name) {
      setError("Wpisz imię");
      return;
    }
    if (!data.surname) {
      setError("Wpisz Nazwisko");
      return;
    }
    if (!data.email) {
      setError("Wpisz E-mail");
      return;
    }
    if (!data.phone) {
      setError("Wpisz Telefon");
      return;
    }

    const response = await request({
      path: "volunteer",
      method: "POST",
      body: data,
    });

    if (response?.ok) {
      navigate(RouteNames.REGISTER_USER_SUCCESS);
    } else {
      setError(response?.message || "");
    }
  };

  return (
    <div className="register-page">
      <div className="register-page-container">
        <Logo width="180px" />
        <RegisterNav />
        <div className="form">
          <Input placeholder="Imię" name="name" onChange={handleChangeData} />
          <Input
            placeholder="Nazwisko"
            name="surname"
            onChange={handleChangeData}
          />
          <Input
            placeholder="E-mail"
            name="email"
            onChange={handleChangeData}
          />
          <Input
            placeholder="Telefon"
            name="phone"
            onChange={handleChangeData}
          />
          <div className="languages">
            <p className="title">
              Posługuję się językami w stopniu komunikatywnym
            </p>
            <div className="checkboxes">
              <Checkbox
                label="j. polski"
                className="lang"
                name="PL"
                onChange={handleChangeData}
              />
              <Checkbox
                label="j. angielski"
                className="lang"
                name="EN"
                onChange={handleChangeData}
              />
              <Checkbox
                label="j. ukraiński"
                className="lang"
                name="UA"
                onChange={handleChangeData}
              />
              <Checkbox
                label="j. rosyjski"
                className="lang"
                name="RU"
                onChange={handleChangeData}
              />
            </div>
          </div>
          <TextField
            placeholder="Wypisz umiejętności, które mogą przydać się na miejscu"
            name="info"
            onChange={handleChangeInfo}
          />
          <div className="personal-data">
            <Checkbox
              className="personal-data-checkbox"
              checked={usePersonalData}
              onChange={handleChangeUsagePersonalData}
            />
            <p
              className="personal-data-label"
              onClick={() =>
                setShowPersonalDataText(
                  (prevShowPersonalDataText) => !prevShowPersonalDataText
                )
              }
            >
              Klauzula RODO rozwiń?
            </p>
          </div>
          <p
            className={`personal-data-text ${
              showPersonalDataText ? "show" : "hide"
            }`}
          >
            Wyrażam zgodę na przetwarzanie moich danych osobowych, podanych w
            formularzu zgłoszeniowym w zakresie niezbędnym do organizacji
            wolontariatu i funkcjonowania Centrum Pomocy Humanitarnej w Global
            Expo w Warszawie. Administratorem danych osobowych jest GEOperator
            Sp. z o.o. ul. Modlińska 6D, 03-216 Warszawa, KRS 0000684672.
          </p>

          <Button
            className="register-button"
            disabled={!usePersonalData || loading}
            onClick={handleSendData}
          >
            Zgłoś się!
          </Button>

          <p className="error">{error}</p>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
