import React, { useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import VolunteersNav from "../components/VolunteersNav";
import DataInput from "../components/UI/DataInput";
import Table from "../components/Table";
import { useHttp } from "../hooks/useHttp";
import { formatToIso } from "../helpers/Date";
import { Availability, Volunteers } from "../types/VolunteersType";
import { ReactComponent as CheckIcon } from "../assets/icons/check-circle-solid.svg";
import { capitalize } from "../helpers/String";
import Checkbox from "../components/UI/Checkbox";
import { TimesCount } from "../types/AvailabilityType";
import TimeButton from "../components/TimeButton";
import Select from "../components/UI/Select";

const VolunteersStatisticsPage = () => {
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState(formatToIso(new Date()));
  const [rows, setRows] = useState<Array<Array<string | JSX.Element>>>([]);
  const [timeslots, setTimeslots] = useState<TimesCount>({});
  const [selectedPost, setSelectedPost] = useState("");
  const [posts, setPosts] = useState<string[]>([]);

  const { request } = useHttp();

  const columns = [
    "Identyfikator",
    "Imię",
    "Adres e-mail",
    "Telefon",
    "EN",
    "UA",
    "RU",
    "PL",
    "Strefa",
    "Dostępność",
    "Obecność",
  ];

  const handleChangePresent = (id: string) => {
    return async () => {
      await request({
        path: "volunteer",
        method: "PATCH",
        body: {
          _id: id,
          date: selectedDate,
        },
      });
    };
  };

  useEffect(() => {
    (async () => {
      const postsResponse = await request<string[]>({
        path: "availability/posts",
        method: "GET",
      });

      if (postsResponse?.ok) {
        setPosts(postsResponse?.data || []);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await request<Volunteers>({
        path: "volunteer",
        method: "GET",
        params: {
          times: selectedTimes,
          date: selectedDate,
          post: selectedPost,
        },
      });

      if (response?.ok) {
        setRows(
          response?.data?.volunteers.map((row) => {
            const availability: Availability = JSON.parse(
              row.availability || ""
            );

            const job = { ...availability[selectedDate] };

            const timeslots = Object.keys(job || {})
              .filter((zone) => zone !== "present")
              .map((timeslot) => capitalize(timeslot));
            const zones = Object.values(job || {})
              .filter((zone) => typeof zone !== "boolean")
              .map((zone) => capitalize(zone));

            return [
              String(row.number || ""),
              row.name,
              row.email,
              row.phone,
              row.languages["EN"] ? (
                <CheckIcon width="24px" style={{ position: "absolute" }} />
              ) : (
                ""
              ),
              row.languages["UA"] ? (
                <CheckIcon width="24px" style={{ position: "absolute" }} />
              ) : (
                ""
              ),
              row.languages["RU"] ? (
                <CheckIcon width="24px" style={{ position: "absolute" }} />
              ) : (
                ""
              ),
              row.languages["PL"] ? (
                <CheckIcon width="24px" style={{ position: "absolute" }} />
              ) : (
                ""
              ),
              zones.join(", "),
              timeslots.join("; "),
              <Checkbox
                onChange={handleChangePresent(row._id)}
                defaultChecked={Boolean(job.present)}
              />,
            ];
          }) || []
        );
      }

      const availabilityCountResponse = await request<TimesCount>({
        path: "availability/count",
        method: "GET",
        params: {
          times: selectedTimes,
          date: selectedDate,
        },
      });

      if (availabilityCountResponse?.ok) {
        setTimeslots(availabilityCountResponse?.data || {});
      }
    })();
  }, [selectedDate, selectedTimes, selectedPost]);

  const handleChangeTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    const name = event.currentTarget.name;

    if (name) {
      if (selectedTimes.includes(name)) {
        const newSelectedTimes = [...selectedTimes];
        newSelectedTimes.splice(newSelectedTimes.indexOf(name), 1);

        setSelectedTimes(newSelectedTimes);
      } else {
        setSelectedTimes([...selectedTimes, name]);
      }
    }
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleChangePost = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPost(event.target.value);
  };

  return (
    <div className="volunteers-statistics-page">
      <AdminHeader />
      <div className="volunteers-statistics-page-container">
        <div className="header">
          <VolunteersNav />
        </div>
        <div className="content">
          <div className="time-select">
            <DataInput onChange={handleChangeDate} />
            <Select onChange={handleChangePost}>
              {posts.map((post) => (
                <option key={post} value={post}>
                  {capitalize(post)}
                </option>
              ))}
            </Select>
          </div>
          <div className="times">
            {Object.keys(timeslots).map((time) => (
              <TimeButton key={time} name={time} onClick={handleChangeTime}>
                <span>{time}</span>
                <span>{timeslots[time] || 0}</span>
              </TimeButton>
            ))}
          </div>
          {rows.length ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <div className="not-found">
              <p className="not-found-text">Nie znaleziono danych</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VolunteersStatisticsPage;
