import React from "react";

type SelectType = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

const Select: React.FC<SelectType> = ({ ...props }) => {
  return <select className="select" {...props} />;
};

export default Select;
