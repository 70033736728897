import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import RegisterNav from "../components/RegisterNav";
import Input from "../components/UI/Input";
import DataInput from "../components/UI/DataInput";
import Select from "../components/UI/Select";
import Button from "../components/UI/Button";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../types/RouteTypes";
import { useHttp } from "../hooks/useHttp";
import {
  AppliedDate,
  Availability,
  AvailabilityType,
  TimeSlots,
} from "../types/AvailabilityType";
import { formatToIso } from "../helpers/Date";

const RegisterReadyUser = () => {
  const [timeSlots, setTimeSlots] = useState<Availability>({});
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlots>({});
  const [selectedDate, setSelectedDate] = useState(formatToIso(new Date()));
  const [appliedDates, setAppliedDates] = useState<AppliedDate>({});

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const { request, loading } = useHttp();

  const handleRegister = () => {
    navigate(RouteNames.REGISTER_READY_USER_SUCCESS);
  };

  useEffect(() => {
    (async () => {
      const response = await request<AvailabilityType>({
        path: `availability`,
        method: "GET",
        params: {
          date: selectedDate,
        },
      });

      if (response?.ok) {
        setTimeSlots(response.data?.times || {});
      }
    })();
  }, [selectedDate]);

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    setSelectedTimeSlots({});

    const selects = document.querySelectorAll("select");
    selects.forEach((select) => {
      select.value = "";
      select.classList.remove("active");
    });
  };

  const handleChangeJob = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    if (value === "") {
      event.target.classList.remove("active");
      setSelectedTimeSlots((prevTimeSlots) => {
        delete prevTimeSlots[event.target.name];

        return prevTimeSlots;
      });

      return;
    }

    event.target.classList.add("active");

    setSelectedTimeSlots((prevTimeSlots) => ({
      ...prevTimeSlots,
      [event.target.name]: value,
    }));
  };

  const handleApplySelectedTimeSlots = async () => {
    if (!email) {
      setError("Nie poprawny adres email");
      return;
    }

    if (!Object.keys(selectedTimeSlots).length) {
      setError("Wybierz co najmniej 1 okres czasu");
      return;
    }
    setError("");

    const response = await request({
      path: "availability",
      method: "PUT",
      body: {
        email: email.trim(),
        date: selectedDate,
        times: selectedTimeSlots,
      },
    });

    if (response?.ok) {
      setAppliedDates((prevAppliedDate) => ({
        ...prevAppliedDate,
        [selectedDate]: selectedTimeSlots,
      }));
    } else {
      if (response?.message) {
        setError(response.message);
      } else {
        setError("Błędnie wprowadzona poczta lub data");
      }
    }
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className="register-ready-user">
      <div className="register-ready-user-container">
        <Logo width="180px" />
        <RegisterNav />
        <div className="content">
          <Input
            placeholder="Adres e-mail podany przy rejestracji"
            onChange={handleChangeEmail}
          />
          <p className="chose-data">
            Wybierz datę, a następnie przedziały czasowe
          </p>
          <p className="you-can-chose-more">możesz wybrać więcej niż jeden</p>
          <DataInput
            onChange={handleChangeDate}
            value={selectedDate}
            min={formatToIso(new Date())}
          />
          <div className="times">
            {Object.keys(timeSlots).map((timeSlot) => {
              const jobs = Object.keys(timeSlots[timeSlot]).filter(
                (time) => timeSlots[timeSlot][time]
              );
              const times = Object.values(timeSlots[timeSlot]).filter((time) =>
                Number(time)
              );

              return (
                <Select
                  disabled={!times.length}
                  onChange={handleChangeJob}
                  name={timeSlot}
                  value={selectedTimeSlots[timeSlot]}
                >
                  <option value="">{timeSlot} - Niewybrane stanowisko</option>
                  {jobs.map((job) => (
                    <option value={job}>
                      {timeSlot} - {job}
                    </option>
                  ))}
                </Select>
              );
            })}
          </div>
          <div className="error">
            <p>{error}</p>
          </div>
          <Button onClick={handleApplySelectedTimeSlots} disabled={loading}>
            Dodaj termin
          </Button>

          <div className="selected-dates">
            <p className="title">Wybrane daty</p>
            <div className="dates">
              {Object.keys(appliedDates).map((date) => {
                const timeSlots = appliedDates[date];

                return (
                  <div className="date-container">
                    <p className="date">{date}</p>
                    {Object.keys(timeSlots)
                      .sort()
                      .map((timeSlot) => {
                        const job = timeSlots[timeSlot];

                        return (
                          <p className="time">
                            {timeSlot} {job}
                          </p>
                        );
                      })}
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
          <Button
            onClick={handleRegister}
            disabled={!Object.keys(appliedDates).length || loading}
          >
            Zgłoś swoją dostępność
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterReadyUser;
