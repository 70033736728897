import React, { useEffect, useRef } from "react";
import { ReactComponent as ArrowBackIcon } from "../../assets/icons/ArrowBackIcon.svg";
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/ArrowForwardIcon.svg";

type DataInputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const DataInput: React.FC<DataInputType> = (props) => {
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (input?.current) {
      input.current.valueAsDate = new Date();
    }
  }, []);

  const handleBackData = () => {
    if (input.current) {
      const newDate = input.current.valueAsDate || new Date();
      newDate.setDate(newDate.getDate() - 1);

      input.current.valueAsDate = newDate;

      const event = new Event("input", { bubbles: true });
      input.current.dispatchEvent(event);
    }
  };

  const handleForwardData = () => {
    if (input.current) {
      const newDate = input.current.valueAsDate || new Date();
      newDate.setDate(newDate.getDate() + 1);

      input.current.valueAsDate = newDate;

      const event = new Event("input", { bubbles: true });
      input.current.dispatchEvent(event);
    }
  };

  const isDisabledBack = () => {
    if (input.current && props.min) {
      const currentDate = input.current.valueAsDate;
      const minDate = new Date(props.min);

      return currentDate?.getDate() === minDate.getDate();
    }

    return false;
  };

  const isDisabledForward = () => {
    if (input.current && props.max) {
      const currentDate = input.current.valueAsDate;
      const maxDate = new Date(props.max);

      return currentDate?.getDate() === maxDate.getDate();
    }

    return false;
  };

  return (
    <div className="data-input-container">
      <button
        className="data-input-button back"
        onClick={handleBackData}
        disabled={isDisabledBack()}
      >
        <ArrowBackIcon width="24px" />
      </button>
      <input type="date" ref={input} {...props} />
      <button
        className="data-input-button forward"
        onClick={handleForwardData}
        disabled={isDisabledForward()}
      >
        <ArrowForwardIcon width="24px" />
      </button>
    </div>
  );
};

export default DataInput;
