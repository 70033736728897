import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { useGlobalState } from "../hooks/useGlobalState";
import { useHttp } from "../hooks/useHttp";
import jwtDecode from "jwt-decode";
import { IPayload } from "../types/UserTypes";
import Input from "../components/UI/Input";
import Button from "../components/UI/Button";

const LoginPage: React.FC = () => {
  const [, setUserRole] = useGlobalState("userRole");
  const [data, setData] = useState({
    login: "",
    password: "",
  });
  const [error, setError] = useState("");

  const { request, loading } = useHttp();

  const handleLogin = async () => {
    if (!data.login) {
      setError("Wpisz login");
      return;
    }

    if (!data.password) {
      setError("Wprowadź hasło");
    }

    const response = await request({
      path: "user/login",
      method: "PATCH",
      body: {
        login: data.login.trim(),
        password: data.password.trim(),
      },
    });

    if (!response?.ok) {
      setError(
        response?.message || "Problem z serwerem. Spróbuj ponownie później"
      );
      return;
    }

    localStorage.setItem("token", response.token!);

    const payload: IPayload = jwtDecode(response.token!);

    setUserRole(payload.role);
  };

  const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;

    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  return (
    <div className="login-page">
      <div className="login-page-container">
        <div className="logo">
          <Logo />
        </div>
        <div className="form">
          <div className="login-input-container">
            <Input
              name="login"
              type="text"
              placeholder="Login"
              onChange={handleChangeData}
            />
          </div>
          <div className="password-input-container">
            <Input
              type="password"
              name="password"
              placeholder="Hasło"
              onChange={handleChangeData}
            />
          </div>
          <Button
            className="login-button"
            onClick={handleLogin}
            disabled={loading}
          >
            Zaloguj
          </Button>
        </div>
        <div className="error">
          <p className="text">{error}</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
