import React, { useEffect, useMemo, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import Table from "../components/Table";
import { ReactComponent as SearchIcon } from "../assets/icons/searchIcon.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/check-circle-solid.svg";
import { useHttp } from "../hooks/useHttp";
import debounce from "lodash.debounce";
import { Volunteers } from "../types/VolunteersType";
import VolunteersNav from "../components/VolunteersNav";
import Input from "../components/UI/Input";

const VolunteersPage = () => {
  const [rows, setRows] = useState<Array<Array<string | JSX.Element>>>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { request } = useHttp();

  const columns = [
    "Identyfikator",
    "Imię",
    "Nazwisko",
    "Adres e-mail",
    "Telefon",
    "EN",
    "UA",
    "RU",
    "PL",
    "Uwagi",
  ];

  const handleChangePresent = (id: string) => {
    return debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
      await request({
        path: "volunteer/number",
        method: "POST",
        body: {
          _id: id,
          number: event.target.value,
        },
      });
    }, 400);
  };

  useEffect(() => {
    (async () => {
      const response = await request<Volunteers>({
        path: `volunteer?search=${searchQuery}`,
        method: "GET",
      });

      if (response?.ok) {
        setRows(
          response?.data?.volunteers.map((row) => [
            <Input
              onChange={handleChangePresent(row._id)}
              defaultValue={row.number || ""}
            />,
            row.name,
            row.surname,
            row.email,
            row.phone,
            row.languages["EN"] ? (
              <CheckIcon
                width="30px"
                style={{ position: "absolute", marginTop: "-8px" }}
              />
            ) : (
              ""
            ),
            row.languages["UA"] ? (
              <CheckIcon
                width="30px"
                style={{ position: "absolute", marginTop: "-8px" }}
              />
            ) : (
              ""
            ),
            row.languages["RU"] ? (
              <CheckIcon
                width="30px"
                style={{ position: "absolute", marginTop: "-8px" }}
              />
            ) : (
              ""
            ),
            row.languages["PL"] ? (
              <CheckIcon
                width="30px"
                style={{ position: "absolute", marginTop: "-8px" }}
              />
            ) : (
              ""
            ),
            row.info,
          ]) || []
        );
      }
    })();
  }, [searchQuery]);

  const handleChangeSearchQuery = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const debouncedHandleChangeSearchQuery = useMemo(
    () => debounce(handleChangeSearchQuery, 400),
    []
  );

  return (
    <div className="volunteers-page" style={{ height: "100vh" }}>
      <AdminHeader />
      <div className="volunteers-page-container">
        <div className="header">
          <VolunteersNav />
          <div className="search">
            <input
              type="search"
              onChange={debouncedHandleChangeSearchQuery}
              placeholder="Wpisz imię lub adres e-mail"
            />
            <button>
              <SearchIcon fill="#F55268" />
            </button>
          </div>
        </div>
        {rows.length ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <div className="not-found">
            <p className="not-found-text">Nie znaleziono danych</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VolunteersPage;
