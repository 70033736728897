import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { adminRoutes, publicRoutes, volunteerRoutes } from '../router/routes';
import { UserRole } from '../types/UserTypes';
import { IRoute } from '../types/RouteTypes';
import { useGlobalState } from '../hooks/useGlobalState';

const AppRouter = () => {
  const [userRole] = useGlobalState('userRole');
  const [routes, setRoutes] = useState<IRoute[]>(publicRoutes);

  useEffect(() => {
    switch (userRole) {
      case UserRole.Unauthorized:
        setRoutes(publicRoutes);
        break;
      case UserRole.Register:
        setRoutes(volunteerRoutes);
        break;
      case UserRole.Unregister:
        setRoutes(volunteerRoutes);
        break;
      case UserRole.Admin:
        setRoutes(adminRoutes);
        break;
    }
  }, [userRole]);

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={route.component ? <route.component /> : route.element}
        />
      ))}
    </Routes>
  );
};

export default AppRouter;
