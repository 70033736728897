import { Navigate } from "react-router-dom";
import { IRoute, RouteNames } from "../types/RouteTypes";
import LoginPage from "../pages/LoginPage";
import ScanPage from "../pages/ScanPage";
import VolunteersPage from "../pages/VolunteersPage";
import StatisticsPage from "../pages/StatisticsPage";
import VolunteersStatisticsPage from "../pages/VolunteersStatisticsPage";
import RegisterPage from "../pages/RegisterPage";
import RegisterReadyUser from "../pages/RegisterReadyUser";
import SuccessRegisterPage from "../pages/SuccessRegisterPage";
import SuccessRegisterReadyUserPage from "../pages/SuccessRegisterReadyUserPage";

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.LOGIN,
    component: LoginPage,
  },
  {
    path: RouteNames.REGISTER_USER,
    component: RegisterPage,
  },
  {
    path: RouteNames.REGISTER_USER_SUCCESS,
    component: SuccessRegisterPage,
  },
  {
    path: RouteNames.REGISTER_READY_USER,
    component: RegisterReadyUser,
  },
  {
    path: RouteNames.REGISTER_READY_USER_SUCCESS,
    component: SuccessRegisterReadyUserPage,
  },
  {
    path: "*",
    element: <Navigate to={RouteNames.LOGIN} />,
  },
];

export const volunteerRoutes: IRoute[] = [
  {
    path: RouteNames.SCAN,
    component: ScanPage,
  },
  {
    path: "*",
    element: <Navigate to={RouteNames.SCAN} />,
  },
];

export const adminRoutes: IRoute[] = [
  {
    path: RouteNames.ADMIN,
    element: <Navigate to={RouteNames.VOLUNTEERS} />,
  },
  {
    path: RouteNames.REGISTER,
    component: ScanPage,
  },
  {
    path: RouteNames.UNREGISTER,
    component: ScanPage,
  },
  {
    path: RouteNames.VOLUNTEERS,
    component: VolunteersPage,
  },
  {
    path: RouteNames.VOLUNTEERS_STATISTICS,
    component: VolunteersStatisticsPage,
  },
  {
    path: RouteNames.STATISTICS,
    component: StatisticsPage,
  },
  {
    path: RouteNames.REGISTER_USER,
    component: RegisterPage,
  },
  {
    path: RouteNames.REGISTER_USER_SUCCESS,
    component: SuccessRegisterPage,
  },
  {
    path: RouteNames.REGISTER_READY_USER,
    component: RegisterReadyUser,
  },
  {
    path: RouteNames.REGISTER_READY_USER_SUCCESS,
    component: SuccessRegisterReadyUserPage,
  },
  {
    path: "*",
    element: <Navigate to={RouteNames.STATISTICS} />,
  },
];
