import React from "react";
import { NavLink } from "react-router-dom";
import { RouteNames } from "../types/RouteTypes";

const VolunteersNav = () => {
  return (
    <div className="volunteers-nav">
      <NavLink to={RouteNames.VOLUNTEERS} className="link" end>
        Wolontariusze
      </NavLink>
      <NavLink to={RouteNames.VOLUNTEERS_STATISTICS} className="link" end>
        Statystyki
      </NavLink>
    </div>
  );
};

export default VolunteersNav;
