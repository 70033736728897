import React from "react";
import { NavLink } from "react-router-dom";
import { RouteNames } from "../types/RouteTypes";

const RegisterNav = () => {
  return (
    <div className="register-nav">
      <NavLink to={RouteNames.REGISTER_USER} className="link" end>
        Zarejestruj się
      </NavLink>
      <NavLink to={RouteNames.REGISTER_READY_USER} className="link" end>
        Zgłaszam swoją dostępność
      </NavLink>
    </div>
  );
};

export default RegisterNav;
