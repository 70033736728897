import React from "react";

type TextAreaType = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const TextField: React.FC<TextAreaType> = ({ className, ...props }) => {
  return <textarea className={`textfield ${className || ""}`} {...props} />;
};

export default TextField;
