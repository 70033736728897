import { IGlobalState } from '../types/GlobalStateType';
import { createGlobalState } from 'react-hooks-global-state';
import { IPayload, UserRole } from '../types/UserTypes';
import jwtDecode from 'jwt-decode';

const token = localStorage.getItem('token');
const payload: IPayload | null = token ? jwtDecode(token) : null;

const initialState: IGlobalState = {
  userRole: payload?.role || UserRole.Unauthorized,
};

export const { useGlobalState } = createGlobalState(initialState);
