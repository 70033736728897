import React from "react";

type InputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input: React.FC<InputType> = ({ className, children, ...props }) => {
  return (
    <div className="input-container">
      <input className={`input ${className || ""}`} {...props} />
      {children}
    </div>
  );
};

export default Input;
