import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as ScanIcon } from "../assets/scanIcon.svg";
import { ReactComponent as SuccessIcon } from "../assets/successIcon.svg";
import { useGlobalState } from "../hooks/useGlobalState";
import { UserRole } from "../types/UserTypes";
import { useNavigate } from "react-router-dom";

const ScanPage = () => {
  const [userRole, setUserRole] = useGlobalState("userRole");

  const [successScan, setSuccessScan] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    setUserRole(UserRole.Unauthorized);
    navigate("/");
  };

  return (
    <div className="scan-page">
      <div className="header">
        <Logo />
        <button className="logout" onClick={handleLogout}>
          Wyloguj
        </button>
      </div>
      <div className="content">
        <div className="user-role">{userRole}</div>
        <div className="scan-code-container">
          {successScan ? (
            <div className="success">
              <SuccessIcon />
              <div className="text">Opaska zarejestrowana w systemie</div>
            </div>
          ) : (
            <div className="waiting">
              <ScanIcon />
              <div className="text">Zeskanuj opaskę</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanPage;
