import React, { useMemo } from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions<"line"> = {
  responsive: true,
  scales: {
    xAxes: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 18,
        },
      },
      alignToPixels: true,
      offset: true,
    },
    yAxes: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 18,
        },
        backdropColor: "white",
      },
      alignToPixels: true,
      offset: true,
    },
  },
  backgroundColor: "red",
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    line: {},
  },
};

interface IProps {
  data: [string[], number[], number[]];
}

const Diagram: React.FC<IProps> = ({ data: datasets }) => {

  const data: ChartData<'line'> = useMemo(() => {
    return {
      labels: datasets[0],
      datasets: [
        {
          label: 'register',
          data: datasets[1],
          borderColor: '#9EEA18',
          backgroundColor: '#9EEA1800',
          borderWidth: 2,
          pointRadius: 10,
          pointHoverRadius: 15,
          pointStyle: 'circle',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 3,
          pointBackgroundColor: '#FFF',
        },
        {
          label: 'unregister',
          data: datasets[2],
          borderColor: '#E90909',
          backgroundColor: '#E9090900',
          borderWidth: 2,
          pointRadius: 10,
          pointHoverRadius: 15,
          pointStyle: 'circle',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 3,
          pointBackgroundColor: '#FFF',
        },
      ],
    };
  }, [datasets]);

  return <Line options={options} data={data} height="80vh" />;
};

export default Diagram;
