import { useCallback, useState } from "react";
import { RequestArgs, RequestFunction, Response } from "../types/UseHttp";
import axiosInstance from "../utils/axios";

export const useHttp = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const request: RequestFunction = useCallback(
    async <ResponseType>(args: RequestArgs) => {
      const { path, method, body, params } = args;

      setLoading(true);
      const response = await axiosInstance.request<Response<ResponseType>>({
        method,
        url: path,
        data: body,
        params,
      });

      if (response.status !== 200) {
        setError(true);
        setLoading(false);
        return null;
      }

      setLoading(false);

      return response.data;
    },
    []
  );
  return { error, request, loading };
};
