import axios from "axios";

const BASE_URL = "https://api.glexpo.nc-one.com/api";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    config.headers = {
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log("all request errors cancelled", error);
    } else {
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      return window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
