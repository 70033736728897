import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useHttp } from "../hooks/useHttp";
import { RouteNames } from "../types/RouteTypes";

const AdminHeader = () => {
  const { request } = useHttp();

  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await request({
      path: "user/logout",
      method: "PATCH",
    });

    if (response?.ok) {
      localStorage.removeItem("token");

      navigate("/");
    }
  };

  return (
    <div className="admin-header">
      <div className="nav-container">
        <Logo className="logo" />
        <div className="nav">
          <NavLink to={RouteNames.REGISTER} className="link">
            Rejestracja
          </NavLink>
          <NavLink to={RouteNames.UNREGISTER} className="link">
            Wyrejestrowanie
          </NavLink>
          <NavLink to={RouteNames.STATISTICS} className="link">
            Statystyki
          </NavLink>
          <NavLink to={RouteNames.VOLUNTEERS} className="link">
            Wolontariusze
          </NavLink>
        </div>
      </div>
      <button className="logout" onClick={handleLogout}>
        Wyloguj
      </button>
    </div>
  );
};

export default AdminHeader;
