import React, { useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import { useSearchParams } from "react-router-dom";
import Diagram from "../components/Diagram";
import { useHttp } from "../hooks/useHttp";

enum Category {
  K = "Kobieta",
  M = "Mężczyzna",
  D = "Dzieci",
  R = "Razem",
}

const catagories = {
  [Category.K]: Category.K,
  [Category.M]: Category.M,
  [Category.D]: Category.D,
  [Category.R]: "",
};

const StatisticsPage = () => {
  const [data, setData] = useState<[string[], number[], number[]]>([
    [],
    [],
    [],
  ]);
  const [count, setCount] = useState({
    Kobieta: 0,
    Mężczyzna: 0,
    Dzieci: 0,
    Razem: 0,
  });
  const [search, setSearch] = useSearchParams();
  const category = search.get("category");
  const { request } = useHttp();

  useEffect(() => {
    (async () => {
      const response = await request({
        path: `refugee/statistic${category ? `?category=${category}` : ""}`,
        method: "GET",
        params: {
          category: category || "",
        },
      });

      if (response?.ok) {
        const days = Array.from(
          new Set(
            Object.keys(
              Object.assign(
                response.data.registered,
                response.data.unregistered
              )
            )
          )
        );
        const registered = days.map(
          (day: string) => response.data.registered[day] || 0
        );
        const unregistered = days.map(
          (day: string) => response.data.unregistered[day] || 0
        );

        setCount(response.data.count);
        setData([days, registered, unregistered]);
      }
    })();
  }, [category]);

  return (
    <div className="statistics-page">
      <AdminHeader />
      <div className="content">
        <div className="cards">
          {Object.keys(count).map((key: string) => (
            <div className="card" key={key}>
              <p className="card-title">{key}</p>
              <p className="card-value">{count[key as Category]}</p>
            </div>
          ))}
        </div>
        <div className="diagram">
          <div className="diagram-header">
            <div className="diagram-header-text">
              Zarejestrowani / Wyrejestrowani - ostatnie 7 dni
            </div>
            <div className="diagram-header-nav">
              {Object.keys(catagories).map((key: string) => (
                <span
                  key={key}
                  className={`link ${
                    category === catagories[key as Category] ? "active" : ""
                  }`}
                  onClick={() =>
                    setSearch({ category: catagories[key as Category] })
                  }
                >
                  {key}
                </span>
              ))}
            </div>
          </div>
          <div className="diagram-container">
            <Diagram data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsPage;
