import React, { useEffect, useRef, useState } from "react";

type CheckboxType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface IProps {
  label?: string;
}

const Checkbox: React.FC<CheckboxType & IProps> = ({
  label,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = useState(false);

  const checkbox = useRef<HTMLInputElement>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setChecked(isChecked);

    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (checkbox.current?.checked) {
      setChecked(checkbox.current.checked);
    }
  }, [checkbox.current?.checked]);

  return (
    <label className="checkbox-container">
      <span className={"checkbox" + (checked ? " checkbox-checked" : "")}>
        <input
          type="checkbox"
          onChange={handleOnChange}
          ref={checkbox}
          {...props}
        />
        <span className="checkbox-inner" />
      </span>
      {label && <span className="checkbox-label">{label}</span>}
    </label>
  );
};

export default Checkbox;
