import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as SuccessIcon } from "../assets/successIcon.svg";

const SuccessRegisterReadyUserPage = () => {
  return (
    <div className="success-register-ready-user-page">
      <div className="success-register-ready-user-page-container">
        <Logo width="350px" />
        <div className="content">
          <p className="title">Dziękujemy!</p>
          <SuccessIcon width="100px" height="100px" />
          <p className="description">
            Na Twój adres e-mail wysłaliśmy potwierdzenie ze zgłoszonymi datami
            oraz ważne informacje, z którymi zapoznaj się przed przyjściem.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessRegisterReadyUserPage;
