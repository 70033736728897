export enum UserRole {
  Unauthorized = "Unauthorized",
  Admin = "Superadmin",
  Register = "Rejestruj",
  Unregister = "Wyrejestruj",
}

export interface IPayload {
  _id: string;
  role: UserRole;
  exp: number;
  iat: number;
}
