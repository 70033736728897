import React from "react";

type ButtonType = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button: React.FC<ButtonType> = ({ className, ...props }) => {
  return <button className={`button ${className || ""}`} {...props} />;
};

export default Button;
